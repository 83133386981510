* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Krungthep';
  src: url('krungthep.woff2') format('woff2'), url('krungthep.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html,
body,
#root {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* overflow: hidden; */
}

#root {
  overflow: auto;
}

body {
  background: white;
  color: black;
  font-family: Krungthep, sans-serif;
}

header {
  position: absolute;
  padding: 1em;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
}

header img {
  width: 5em;
  height: 5em;
}

section {
  margin: 4em 1em 4em 40vw;
}

footer {
  background: black;
  color: white;
  text-align: center;
  padding: 2em 1em;
}
